import { useState } from 'react';

import spinner from '../assets/img/loading-spinner.png';
import { getLogger } from '../logger/logger';
import { cn } from '../utils/cn';
import { useCreateGuestUser } from './Training/utils';
import { useUserGetter } from './UserContext';

type EmbeddedPageProps = {
  src: string;
  title: string;
  className?: string;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  buttonClassName?: string;
  onAuth?: () => void;
};

const logger = getLogger().scoped('EmbeddedPageWithGuestLogin');

function ContainedLoadingSpinner() {
  return (
    <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-60'>
      <div className='w-45 h-16 bg-lp-black-001 border-2 border-black-001 rounded-xl text-white text-base font-bold flex items-center justify-center'>
        <img src={spinner} alt='spinner' className='w-7.5 h-7.5 mr-4' />
        <span>Loading...</span>
      </div>
    </div>
  );
}

export function EmbeddedPageWithGuestLogin({
  src,
  title,
  className,
  containerClassName,
  containerStyle,
  buttonClassName,
  onAuth,
}: EmbeddedPageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [showIframe, setShowIframe] = useState(false);
  const createGuestUser = useCreateGuestUser();
  const getUser = useUserGetter();
  const user = getUser();

  const handleAuthAndShow = async () => {
    try {
      onAuth?.();
      await createGuestUser();
      setShowIframe(true);
    } catch (error) {
      logger.error('Failed to create guest user:', error);
    }
  };

  const baseContainerClasses =
    'relative h-full min-h-[400px] bg-black overflow-hidden';

  if (!user.id && !showIframe) {
    return (
      <div
        className={cn(
          baseContainerClasses,
          'flex items-center justify-center',
          containerClassName
        )}
        style={containerStyle}
      >
        <button
          onClick={handleAuthAndShow}
          className={cn(
            'btn-secondary font-Montserrat rounded-xl',
            'w-80 h-20 text-2xl font-bold flex items-center justify-center text-[#FBB707]',
            'transition-colors duration-200',
            buttonClassName
          )}
          type='button'
        >
          Start Demo
        </button>
      </div>
    );
  }

  return (
    <div
      className={cn(baseContainerClasses, containerClassName)}
      style={containerStyle}
    >
      {isLoading && <ContainedLoadingSpinner />}
      <iframe
        src={src}
        className={cn('w-full h-full border-none absolute inset-0', className)}
        title={title}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
}
