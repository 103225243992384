import { Link, type MetaFunction, useNavigate } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';

import { HelpWidget } from '../../../../app/components/HelpWidget/HelpWidget';
import { useLearningAnalytics } from '../../../analytics/learning';
import config from '../../../config';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { getStaticAssetPath } from '../../../utils/assets';
import { makeMarketLink } from '../../../utils/makeLinks';
import { EmbeddedPageWithGuestLogin } from '../../EmbeddedPageWithGuestLogin';
import { UGCFileManagerProvider } from '../../Game/UGC';
import { useUGCFileManager } from '../../Game/UGC/CustomGameFileManager';
import {
  type ActionProps,
  type CustomEditorPrimaryAction,
  CustomGamePackPromptEditor,
  type PromptSuggestion,
} from '../../Game/UGC/CustomGamePackPromptEditor';
import { MultipleChoiceBlockIcon } from '../../icons/Block';
import { InstructionBlockIcon } from '../../icons/Block/InstructionBlockIcon';
import { JeopardyBlockIcon } from '../../icons/Block/JeopardyBlockIcon';
import { DocumentIcon } from '../../icons/DocumentIcon';
import { GameIcon } from '../../icons/GameIcon';
import { ImageIcon } from '../../icons/ImageIcon';
import { LPLogo } from '../../icons/LPLogo';
import { ScoreboardIcon } from '../../icons/ScoreboardIcon';
import { InstgramIcon, LinkedInIcon } from '../../icons/SocialIcon';
import { StarIcon } from '../../icons/StarIcon';
import { VolumeIcon } from '../../icons/VolumeIcon';
import { CaseStudyHeader } from '../TrainingStarterStylish';
import { useCreateEmptyGamePack, useCreateGuestUser } from '../utils';
import { LearningLandingFAQs } from './Components/LearningLandingFaqs';
import { LearningLandingHeader } from './Components/LearningLandingHeader';
import { TestimonialCard } from './Components/TestimonialCard';

const EMPLOYEE_ONBOARDING_URL = getStaticAssetPath(
  'images/training/pest-landing/laptop-phone-pest.png'
);
const MULTIPLE_CHOICE_GIF_URL = getStaticAssetPath(
  'images/training/pest-landing/multiple-choice-pest.png'
);
const JEOPARKY_GIF_URL = getStaticAssetPath(
  'images/training/pest-landing/pest-control-jeopardy.webp'
);
const HOW_SCORM_WORKS_URL = getStaticAssetPath('images/how-scorm-works.png');
const READY_TO_DIVE_IN_VID_URL = getStaticAssetPath(
  'videos/ready-to-dive-in.mp4'
);

const BANNER_VIDEO_RESPONSIVE_STYLES = {
  mobile: {
    url: getStaticAssetPath('videos/training-banner-mobile-v3.mp4'),
    poster: getStaticAssetPath('images/training-banner-mobile-v3.png'),
    aspectRatio: '1/1',
    maxHeight: undefined,
    objectFit: 'contain' as const,
  },
  desktop: {
    url: getStaticAssetPath('videos/training-banner-desktop-v3.mp4'),
    poster: getStaticAssetPath('images/training-banner-desktop-v3.png'),
    aspectRatio: undefined,
    maxHeight: '500px',
    objectFit: 'cover' as const,
  },
};

export const meta: MetaFunction = () => {
  return [
    { title: 'Trainings | Luna Park' },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.desktop.poster,
      as: 'image',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.mobile.poster,
      as: 'image',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.desktop.url,
      as: 'fetch',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.mobile.url,
      as: 'fetch',
    },
  ];
};

export function Banner(props: {
  tagline: 'instantly-create' | 'engaging-games';
  widthClasses?: string;
}) {
  const taglineUrl = 'images/training/pest-landing/training-tagline-pest.png';

  return (
    <section className='w-full px-4 py-8 lg:p-16'>
      <div className='max-w-8xl mx-auto flex flex-col-reverse items-center lg:flex-row gap-8'>
        {/* Content Column */}
        <div className='w-full flex flex-col items-center gap-1'>
          <img
            className={`md:max-w-3xl max-w-full ${
              props.widthClasses ?? 'w-4/5 3xl:w-1/3'
            }`}
            src={getStaticAssetPath(taglineUrl)}
            alt='tagline'
          />
          <div className='w-full text-center text-base font-bold text-icon-gray'>
            3x better technician performance through personalized learning
            systems
          </div>
          <div className='w-full max-w-2xl lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl'>
            <UGCFileManagerProvider>
              <Starter
                customAction={(props) => <CustomActionButton {...props} />}
                alternateButton
                starterOrigin='pest-landing'
                width='w-full'
              />
            </UGCFileManagerProvider>
          </div>
        </div>

        {/* Video Column */}
        <div className='w-full flex items-center justify-center'>
          <div className='w-full'>
            <video
              src={getStaticAssetPath(
                'videos/training/pest-landing/banner-video-pest.mp4'
              )}
              className='w-full aspect-video object-cover rounded-lg'
              autoPlay
              loop
              muted
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function CustomActionButton(props: ActionProps) {
  const { disabled, disabledReason, submit, abort } = props;
  const submitting = disabledReason === 'submitting';

  return (
    <button
      type='button'
      className='py-1.5 px-4 flex items-center justify-center btn-delete font-normal text-sms sm:font-bold'
      disabled={disabledReason === 'emptyPrompt' ? false : disabled}
      title={
        disabledReason === 'filesNotReady' ? 'Wait for files to be ready' : ''
      }
      onClick={submitting ? abort : submit}
    >
      {submitting ? 'Stop' : 'Create ✨'}
    </button>
  );
}

type StarterOrigin = 'landing' | 'admin' | 'pest-landing';

const suggestions: PromptSuggestion[] = [
  {
    label: 'Roleplay to improve door to door sales',
    value: 'Roleplay to improve door to door sales',
  },
  {
    label: 'Match images of common household pests',
    value: 'Match images of common household pests',
  },
  { label: 'Quiz to', value: 'Quiz to' },
  { label: 'Game to', value: 'Game to' },
];

function Starter(props: {
  customAction?: CustomEditorPrimaryAction;
  alternateButton?: boolean;
  bottomLabel?: string;
  starterOrigin: StarterOrigin;
  width?: string;
}) {
  const fileman = useUGCFileManager();
  const createGamePack = useCreateEmptyGamePack(null);
  const createUser = useCreateGuestUser();
  const navigate = useNavigate();

  const initFileman = useLiveCallback(async () => {
    if (fileman.inited) return;
    const user = await createUser();
    if (!user) return;
    const pack = await createGamePack();
    if (!pack) return;
    fileman.init(pack.id, []);
  });

  useEffect(() => {
    fileman.uppy.addPreProcessor(initFileman);
    return () => fileman.uppy.removePreProcessor(initFileman);
  }, [fileman.uppy, initFileman]);

  const {
    call: submit,
    state: { state, error },
  } = useLiveAsyncCall(async (prompt: string) => {
    if (prompt.trim().length === 0) {
      fileman.emitMessage('Please type in a topic.', 'error');
      return false;
    }

    const params = new URLSearchParams();

    const user = await createUser();
    if (!user) return false;
    const pack = await createGamePack();
    if (!pack) return false;
    params.set('prompt', prompt);
    params.set('id', pack.id);
    params.set('starter-origin', props.starterOrigin);
    navigate(`/learning/assistant?${params.toString()}`);
    return true;
  });

  return (
    <CustomGamePackPromptEditor
      enabled
      onSubmit={submit}
      onAbort={() => void 0}
      isSubmitting={state.isRunning}
      wrapperClassName='mt-auto py-4'
      active
      autoFocus
      error={error}
      placeholder='What training course are you building today?'
      disableDeactivate
      width={props.width ?? 'w-full md:w-160'}
      borderVariant={props.customAction ? 'gradient' : 'secondary'}
      bottomLabel={
        props.bottomLabel ?? 'Create for free. No credit card required'
      }
      alternateButton={props.alternateButton}
      innerBottomLabel=''
      suggestions={suggestions}
      height={{
        active: {
          withFiles: 'h-68',
          withoutFiles: 'h-56',
        },
        inactive: 'h-20',
      }}
    >
      {props.customAction}
    </CustomGamePackPromptEditor>
  );
}

function Demo() {
  const analytics = useLearningAnalytics();
  const demoUrl = `${config.app.baseUrl}/game-packs/aa988cc5-35d1-4cc3-b6ef-6c740427a1e9/overworld?guest-users=enabled&hide-lp-logo=true`;
  return (
    <section className='w-full py-12 bg-white'>
      <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-center text-center gap-5'>
        <h2 className='text-2xl sm:text-4xl font-Montserrat font-bold text-black'>
          Demo One Of Our Training Experiences
        </h2>
        <p className='text-lg text-icon-gray font-bold'>
          Ideal for pest knowledge retention and infinitely customizable
        </p>
        <div className='w-full h-[750px]'>
          <EmbeddedPageWithGuestLogin
            src={demoUrl}
            title='Pest Control Demo'
            className='rounded-xl'
            containerClassName='w-full h-full rounded-xl'
            onAuth={() =>
              analytics.trackLearningDemoStarted({
                landingPage: 'pest-control',
                target: demoUrl,
              })
            }
          />
        </div>
      </div>
    </section>
  );
}

function InstantlyCreate() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-center text-center gap-5'>
        <h2 className='text-2xl sm:text-4xl font-Montserrat font-bold text-white'>
          <span className='text-red-001'>Instantly</span> Create Interactive
          Training Programs
        </h2>
        <p className='text-lg text-icon-gray font-bold'>
          Create engaging training materials that teams actually complete.
          Choose from three proven formats to elevate your materials and track
          completion.
        </p>
      </div>
    </section>
  );
}

function EngagingCourses() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
        <div className='flex-1'>
          <img
            src={EMPLOYEE_ONBOARDING_URL}
            alt='Employee Onboarding'
            className='w-full h-full object-cover rounded-lg'
          />
        </div>
        <div className='flex-1 flex flex-col space-y-4 text-icon-gray'>
          <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-bold'>
            Engaging Courses: Best for Structured Learning
          </h2>
          <p>
            Deliver professional pest control training that keeps technicians
            engaged throughout. Format includes:
          </p>

          <div className='flex flex-col gap-2 pl-1.5'>
            <div className='flex items-center gap-2.5'>
              <DocumentIcon className='flex-shrink-0 w-5 h-5 fill-current text-tertiary' />
              <span className='text-white font-bold'>
                Slides&nbsp;
                <span className='text-icon-gray font-normal'>
                  to present key ideas.
                </span>
              </span>
            </div>
            <div className='flex items-center gap-2.5'>
              <ImageIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#FF3975]' />
              <span className='text-white font-bold'>
                Images&nbsp;
                <span className='text-icon-gray font-normal'>
                  to make content visually engaging.
                </span>
              </span>
            </div>
            <div className='flex items-center gap-2.5'>
              <VolumeIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#3988FF]' />
              <span className='text-white font-bold'>
                Voiceovers&nbsp;
                <span className='text-icon-gray font-normal'>
                  for added clarity and emphasis.
                </span>
              </span>
            </div>
            <div className='flex items-center gap-2.5'>
              <MultipleChoiceBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#00D0C4]' />
              <span className='text-white font-bold'>
                Basic quizzes&nbsp;
                <span className='text-icon-gray font-normal'>
                  to reinforce and test knowledge.
                </span>
              </span>
            </div>
          </div>

          <p>
            Whether you're training new technicians or upskilling veteran staff,
            our platform provides a familiar yet powerful way to build
            expertise.
          </p>
        </div>
      </div>
    </section>
  );
}

function Quizzes() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
        <div className='flex-1 flex flex-col space-y-4 text-icon-gray order-2 sm:order-1'>
          <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-bold'>
            Quizzes: Test Knowledge, Verify Learning
          </h2>
          <p>
            Reinforce training with competitive quizzes to ensure technicians
            master the material—not just skim through it. Fun challenges verify
            real learning while driving engagement.
          </p>

          <div className='flex flex-col gap-2 pl-1.5'>
            <div className='flex items-center'>
              <MultipleChoiceBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#FF3975]' />
              <span className='ml-2.5 text-white font-bold'>
                Customizable question formats.
              </span>
            </div>
            <div className='flex items-center'>
              <ScoreboardIcon className='flex-shrink-0 w-5 h-5 fill-current text-tertiary' />
              <span className='ml-2.5 text-white font-bold'>
                Real-time scoring to keep participants engaged.
              </span>
            </div>
            <div className='flex items-center'>
              <InstructionBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#7BC0FF]' />
              <span className='ml-2.5 text-white font-bold'>
                Perfect for knowledge checks, team-building activities
              </span>
            </div>
          </div>

          <p>Turn any topic into an exciting challenge!</p>
        </div>

        <div className='flex-1 order-1 sm:order-2  max-w-[220px]'>
          <img
            src={MULTIPLE_CHOICE_GIF_URL}
            alt='Quiz Example'
            className='w-full h-full object-cover rounded-lg'
          />
        </div>
      </div>
    </section>
  );
}

function GamesPlayLearnWin() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
        <div>
          <img
            src={JEOPARKY_GIF_URL}
            className='w-full h-full object-cover rounded-lg max-w-[220px]'
            alt='Jeopardy'
          />
        </div>
        <div className='flex-1 flex flex-col space-y-4 text-icon-gray'>
          <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-bold'>
            Games: Play, Learn, Excel
          </h2>
          <p>
            Turn pest control training into a friendly competition! Our game
            show format makes learning stick by offering experiences inspired
            by:
          </p>

          {/* Icons restored */}
          <div className='flex flex-col gap-2 pl-1.5'>
            <div className='flex items-center'>
              <JeopardyBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#FF3975]' />
              <span className='ml-2.5 text-white font-bold'>
                Jeopardy:{' '}
                <span className='text-icon-gray font-normal'>
                  Test participants’ knowledge across categories.
                </span>
              </span>
            </div>
            <div className='flex items-center'>
              <StarIcon className='flex-shrink-0 w-5 h-5 fill-current text-tertiary' />
              <span className='ml-2.5 text-white font-bold'>
                Who Wants to Be a Millionaire:{' '}
                <span className='text-icon-gray font-normal'>
                  Add suspense with tiered rewards and lifelines.
                </span>
              </span>
            </div>
            <div className='flex items-center'>
              <GameIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#FF3975]' />
              <span className='ml-2.5 text-white font-bold'>
                And More:{' '}
                <span className='text-icon-gray font-normal'>
                  Create your own unique games with custom questions and prizes.
                </span>
              </span>
            </div>
          </div>

          <p>Learning doesn’t have to be boring—make it unforgettable.</p>
        </div>
      </div>
    </section>
  );
}

function ExportScormFiles() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col gap-10'>
        <div className='flex flex-col items-center text-center gap-5'>
          <h2 className='text-2xl sm:text-4xl font-Montserrat font-bold text-white'>
            Integrate with your LMS
          </h2>
          <p className='text-lg text-icon-gray font-bold'>
            Take your custom content to the next level with seamless SCORM file
            exports, making it easy to integrate into your existing Learning
            Management System (LMS). Designed with L&D content creators in mind,
            our SCORM export feature ensures your training materials are
            versatile, scalable, and ready to deliver results.
          </p>
        </div>

        <div className='flex flex-col sm:flex-row items-center gap-20'>
          <div className='flex-1'>
            <img
              src={HOW_SCORM_WORKS_URL}
              alt='How SCORM Works'
              className='w-full h-full object-cover rounded-lg'
            />
          </div>
          <div className='flex-1 flex flex-col gap-4 text-icon-gray'>
            <h3 className='text-2xl sm:text-3xl lg:text-4xl font-bold text-white'>
              How it Works
            </h3>
            <ol className='list-decimal pl-4 space-y-2'>
              <li>
                Create your experience using our{' '}
                <span className='font-bold text-white'>Instant Creator</span>.
              </li>
              <li>Personalize every element to suit your training goals.</li>
              <li>Export as a SCORM file in just a few clicks.</li>
              <li>
                Upload the SCORM file to your LMS and start delivering impactful
                training.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}

type BringInspirationProps = {
  onGetStartedClick?: () => void;
};

function BringInspiration({ onGetStartedClick }: BringInspirationProps) {
  return (
    <section className='bg-[#F8F8F8] w-full pt-8'>
      <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col lg:flex-row items-center gap-8'>
        <div className='flex flex-col gap-4 py-8 lg:py-16 flex-1'>
          <div className='max-w-xl flex flex-col gap-2.5 lg:gap-4'>
            <h2 className='font-Montserrat font-bold text-2xl lg:text-4xl text-black'>
              Instantly Turn Years of Experience Into Engaging Training
            </h2>
            <p className='text-icon-gray text-base font-bold lg:text-xl'>
              Share your years of domain expertise in a format new technicians
              can easily master. Simply share your knowledge, pick a format, and
              watch them come to life.
            </p>
            <button
              className='btn-delete w-40 h-10 flex items-center justify-center text-sms font-bold mt-4'
              onClick={onGetStartedClick}
              type='button'
            >
              Get Started
            </button>
          </div>
        </div>
        <video
          src={READY_TO_DIVE_IN_VID_URL}
          muted
          autoPlay
          loop
          style={{
            maxHeight: '460px',
            maxWidth: '100%',
            objectFit: 'cover',
          }}
          preload='auto'
        />
      </div>
    </section>
  );
}

function Testimonial() {
  return (
    <section className='bg-black w-full relative px-4'>
      <div className='max-w-5xl px-0 sm:px-4 mx-auto flex flex-col items-start lg:items-center gap-4 py-8 lg:pt-16 lg:pb-28 relative z-10'>
        <TestimonialCard
          avatarUrl={getStaticAssetPath(
            'images/training/testimonials/avatars/white-knight.png'
          )}
          name='Justin Hansen'
          position='Director of Training and Development'
          company='White Knight Pest Control'
          companyLogoUrl={getStaticAssetPath(
            'images/training/testimonials/logos/white-knight.png'
          )}
          quote='Having AI roleplay with our techs will save us countless hours!'
        />
      </div>
    </section>
  );
}

function CaseStudy() {
  return (
    <section className='bg-black w-full relative'>
      <div className='absolute inset-0 bg-gradient-to-t from-[#0029FF] opacity-30 to-transparent pointer-events-none' />
      <div className='max-w-5xl sm:h-140 mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-start lg:items-center gap-4 py-8 lg:pt-16 lg:pb-28 relative z-10'>
        <CaseStudyHeader />

        <div className='w-full sm:w-2/3 flex flex-col gap-2.5 lg:gap-4 self-start mt-8'>
          <div className='font-Montserrat font-bold text-2xl lg:text-4xl text-white'>
            KPMG uses gamified learning to increase fees by{' '}
            <span className='text-green-001'>25%</span>
          </div>

          <div className='text-icon-gray text-base lg:text-xl font-bold'>
            Discover how KPMG leveraged a learning game to bridge knowledge
            gaps... increased employee engagement... and fueled remarkable
            revenue growth.
          </div>

          <Link
            className='btn-delete w-40 h-10 flex items-center justify-center text-sms font-bold mt-4'
            to='https://lunapark.com/blog/kpmg-globerunner-a-case-study-in-gamified-learning/'
          >
            Learn More
          </Link>
        </div>

        <img
          className='w-72 absolute bottom-0 right-0 hidden lg:block'
          src={getStaticAssetPath('images/training-case-study-cover.png')}
          alt='case-study'
        />
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer className='w-full bg-black sm:bg-lp-gray-009 py-3 pb-4'>
      <div className='max-w-7xl mx-auto px-6'>
        <div className='flex flex-row items-start sm:items-center justify-between'>
          <div className='flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-16'>
            <LPLogo />
            <div className='flex flex-col sm:flex-row gap-4 sm:gap-8 text-sms'>
              <Link
                to={makeMarketLink('/terms-of-use/')}
                className='text-white hover:text-gray-300'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms of Service
              </Link>
              <Link
                to={makeMarketLink('/privacy-policy/')}
                className='text-white hover:text-gray-300'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className='flex flex-row items-start space-x-4 mt-3.5 sm:mt-0'>
            <Link
              to='https://www.instagram.com/golunapark'
              className='text-white hover:text-gray-300'
              target='_blank'
              rel='noopener noreferrer'
            >
              <InstgramIcon />
            </Link>
            <Link
              to='https://www.linkedin.com/company/golunapark'
              className='text-white hover:text-gray-300'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedInIcon />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

type TrainingGenAIStarterProps = {
  userIsntLoggedIn: boolean;
};

export function LearningPestControlLanding(props: TrainingGenAIStarterProps) {
  const { userIsntLoggedIn } = props;
  const ref = useRef<HTMLDivElement>(null);

  const scrollableRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.background = `url('${getStaticAssetPath(
      'images/ugc/background-landing-page.png'
    )}')`;
    ref.current.style.backgroundRepeat = 'no-repeat';
    ref.current.style.backgroundSize = 'cover';
  }, []);

  const [openHelpWidget, setOpenHelpWidget] = useState(false);

  return (
    <div
      className='w-full h-full overflow-y-auto scrollbar'
      ref={scrollableRef}
    >
      <LearningLandingHeader
        userIsntLoggedIn={userIsntLoggedIn}
        source='pest-control'
        lpLogoRedirect='/learning/pest-control'
      />
      <Banner tagline='instantly-create' widthClasses='w-full lg:3/5' />
      <main className='flex flex-col items-center'>
        <Demo />
        <InstantlyCreate />
        <EngagingCourses />
        <div ref={ref} className='w-full'>
          <Quizzes />
          <GamesPlayLearnWin />
          <ExportScormFiles />
        </div>
        <BringInspiration onGetStartedClick={scrollToTop} />
        <Testimonial />
        <CaseStudy />
        <LearningLandingFAQs />
      </main>
      <Footer />
      <HelpWidget open={openHelpWidget} setOpen={setOpenHelpWidget} />
    </div>
  );
}
